<template>
  <base-layout>
    <div class="bg">
      <div class="content-wrapper">
        <h1>Личный кабинет</h1>
        <tabs-db :links="links">
          <template v-slot:content>
            <table-db :header="prizes.header" :rows="prizes.rows" />
          </template>
        </tabs-db>
      </div>
    </div>
  </base-layout>
</template>

<script>
import BaseLayout from "@/layouts/BaseLayout"
import TabsDb from "@/components/TabsDb"
import TableDb from "@/components/TableDb"

export default {
  name: "PrizesPersonal",
  components: {
    BaseLayout,
    TabsDb,
    TableDb
  },

  data() {
    return {
      links: [
        {
          name: "Профайл",
          href: "/personal/profile"
        },
        {
          name: "История",
          href: "/personal/history"
        },
        {
          name: "Призы",
          href: "/personal/prizes"
        }
      ],
      prizes: {
        header: ["№", "Вид приза", "Приз", "Статус"],
        rows: []
      }
    }
  },

  mounted() {
    let counter = 0

    this.$store.state.user.profile.prizes.forEach(item => {
      this.prizes.rows.push({
        key: ++counter,
        cols: [
          {
            key: 1,
            value: counter.toString()
          },
          {
            key: 2,
            value: "Гарантированный"
          },
          {
            key: 3,
            value: item.name
          },
          {
            key: 4,
            value: "ПОЛУЧЕН"
          }
        ]
      })
    })
  }
}
</script>

<style lang="scss" scoped>
@import "../../assets/scss/variables";

h1 {
  margin-bottom: 45px;
  font-size: 60px;
  text-align: center;
}

.bg {
  position: relative;
  background-color: #fff;
  color: #3F3F3F;

  &::before, &::after {
    content: "";
    position: absolute;
    display: block;
    background-size: cover;
  }

  &::before {
    width: 90px;
    height: 100px;
    top: 60px;
    left: 10%;
    //
  }

  &::after {
    width: 100px;
    height: 95px;
    top: 60px;
    right: 10%;
    //
  }
}

.content-wrapper {
  max-width: 1200px;
  margin: 0 auto;
  padding: 45px 0 90px 0;
}

@media screen and (max-width: $lg) {
  h1 {
    font-size: 30px;
  }

  .bg::before, .bg::after {
    background-image: none;
  }

  .content-wrapper {
    padding-bottom: 0;
  }
}

img {
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  -o-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  filter: grayscale(100%);
}
</style>